import { Mail, Notifications, Pets } from '@mui/icons-material'
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  InputBase,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
})

const Search = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  padding: '0 10px',
  borderRadius: theme.shape.borderRadius,
  width: '40%',
}))

const Icons = styled(Box)(({ theme }) => ({
  display: 'none',
  alignItems: 'center',
  gap: '20px',
  // Lớn hơn breakpoint sm
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}))

const UserBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

function Navbar(props) {
  const [openMenu, setOpenMenu] = useState(false)

  return (
    <AppBar position="sticky">
      <StyledToolbar>
        <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'block' } }}>
          MUI React
        </Typography>
        <Pets sx={{ display: { xs: 'block', sm: 'none' } }}></Pets>
        <Search>
          <InputBase placeholder="Seach..."></InputBase>
        </Search>
        <Icons>
          <Badge badgeContent={4} color="error">
            <Mail></Mail>
          </Badge>
          <Badge badgeContent={2} color="error">
            <Notifications></Notifications>
          </Badge>
          <Avatar sx={{ width: 30, height: 30 }} src="" onClick={(e) => setOpenMenu(true)}></Avatar>
        </Icons>
        <UserBox>
          <Avatar sx={{ width: 30, height: 30 }} src="" onClick={(e) => setOpenMenu(true)}></Avatar>
          <Typography>Nhat Long</Typography>
        </UserBox>
      </StyledToolbar>
      <Menu
        open={openMenu}
        onClose={(e) => setOpenMenu(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem>Profile</MenuItem>
        <MenuItem>My account</MenuItem>
        <MenuItem>Logout</MenuItem>
      </Menu>
    </AppBar>
  )
}

export default Navbar
